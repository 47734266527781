import React, { ReactNode } from 'react'

export default function BlockTitle({
  title,
  description = '',
  children = undefined,
  size = 'md',
  contentStart = undefined,
  variant = 'black',
  className = '',
  strike = false,
  fontWeight = 'bold',
}: {
  title: string | ReactNode
  description?: string
  children?: any | undefined
  size?: 'xs' | 'sm' | 'md' | 'lg'
  variant?: 'grey' | 'black'
  contentStart?: ReactNode | undefined
  className?: string
  strike?: boolean
  fontWeight?: 'extrabold' | 'bold' | 'thin' | 'light'
}) {
  const sizeClass = {
    xs: 'text-lg',
    sm: 'text-xl',
    md: 'text-2xl',
    lg: 'text-3xl',
  }
  const fontWeightClass = {
    extrabold: 'font-extrabold',
    bold: 'font-bold',
    thin: 'text-thin',
    light: 'text-light',
  }

  return (
    <div
      className={
        'flex max-md:flex-col max-md:justify-end max-md:gap-2 flex-row justify-between gap-4 ' +
        className
      }
    >
      <div className={'flex justify-start'}>
        {contentStart && <div className={'mr-4'}>{contentStart}</div>}
        <div>
          <h2
            className={
              sizeClass[size] +
              ' ' +
              fontWeightClass[fontWeight] +
              ' ' +
              (strike ? 'line-through font-base' : 'tracking-tight')
            }
          >
            {title}
          </h2>
          {description && (
            <div
              className={
                'text-sm font-light ' + (variant === 'black' && 'mt-1')
              }
            >
              {description}
            </div>
          )}
        </div>
      </div>
      {children && (
        <div className={'flex flex-row gap-4 text-sm items-center'}>
          {children}
        </div>
      )}
    </div>
  )
}
