'use client'
import NextImage from 'next/image'
import logoPng from '@jobmojito/app/images/logo.png'
import logoResume from '../../../apps/resumecheck-net/app/images/headerLogo.png'
import logoInterview from '../../../apps/jobinterview-coach/app/images/headerLogo.png'
import React from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import platformLanguagesRaw from '@jobmojito/app/utils/data/platform-languages.json'

export default function MerchantLogo({
  isUserLogged = false,
  size = 'sm',
  domain,
  externalLogoUrl,
  logoImageUrl,
}: {
  isUserLogged?: boolean
  size?: 'sm' | 'lg'
  domain: string
  externalLogoUrl?: string
  logoImageUrl: string
}): React.JSX.Element {
  // get pathname
  const pathname = usePathname()
  const [isHomepage, setIsHomepage] = React.useState(false)

  //to AI: write use effect based on pathname and console log
  React.useEffect(() => {
    const calculatedIsHomepage =
      pathname === '/' ||
      platformLanguagesRaw.find((l) => l.code === pathname.slice(1)) !== undefined
/*    console.log({
      pathname: pathname,
      externalLogoUrl: externalLogoUrl,
      calculatedIsHomepage,
    })*/

    setIsHomepage(calculatedIsHomepage)
  }, [pathname])

  const pixelSize = size === 'lg' ? 400 : 200

  // select what icon to show based on the domain
  let logoImage
  let logoClassNames
  if (
    domain === 'jobinterview-coach.vercel.app' ||
    domain === 'jobinterview.coach' ||
    domain === 'localhost:3001'
  ) {
    logoImage = logoInterview
    logoClassNames = 'min-w-[150px] md:min-w-[300px]'
  } else if (
    domain === 'resumecheck-net.vercel.app' ||
    domain === 'resumecheck.net' ||
    domain === 'localhost:3002'
  ) {
    logoImage = logoResume
    logoClassNames = 'min-w-[150px] md:min-w-[300px]'
  } else {
    logoImage = logoImageUrl || logoPng
    logoClassNames = 'w-32 md:w-40'
  }

  return (
    <Link
      href={
        isHomepage &&
        externalLogoUrl !== undefined &&
        externalLogoUrl !== null &&
        externalLogoUrl !== ''
          ? externalLogoUrl
          : isUserLogged
            ? '/'
            : '/'
      }
      target={
        isHomepage &&
        externalLogoUrl !== undefined &&
        externalLogoUrl !== null &&
        externalLogoUrl !== ''
          ? '_blank'
          : '_self'
      }
    >
      <NextImage
        src={logoImage}
        className={'h-full ' + logoClassNames}
        style={{ objectFit: 'contain', backgroundPositionX: 'left' }}
        alt={'Logo'}
        width={pixelSize}
        height={pixelSize}
      />
    </Link>
  )
}
